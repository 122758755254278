.register-organization {
  .card-header {
    .card-img {
      position: absolute;
      left: 0;
      top: -39%;
      width: 110%;
    }
  }
  .organization-name {
    margin-top: 50px;
    .org-name {
      color: #000 !important;
    }
  }
}