.tx-note {

}
.note-modal {
  .title-up {
    margin-left: 15px;
  }
  .modal-body {
    //padding-top: 5px;
  }
  p {
    padding: 10px 20px;
    min-height: 111px;
    border: 1px solid #ffcb80;
    border-radius: 0 25px 25px 25px;
    background: #fff8eb;
  }
}