body {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

iframe {
    border: 0;
    //visibility: hidden;
    //opacity: 0;
    //pointer-events: none;
}
iframe[id="tidio-chat-iframe"] {
  pointer-events: all;
}

figcaption,
figure,
main {
    display: block;
}

main {
	overflow: hidden;
}
