.meal-voucher {

  .program_plus_pool {
    display: flex;
    flex-direction: row;
  }

  .meal-program-list {
    display: flex;
    flex-direction: column;
    width: 50%;
    justify-content: space-between;
    align-items: flex-start;


    .meal-program {
      border: 1px solid pink;
      display: inline-block;
      padding: 20px 35px 10px;
      border-radius: 4px;
      width: calc(100% - 40px);
      margin-bottom: 20px;

      .meal-program-body {
        padding-top: 10px;
        .status-tag {
          background-color: green;
          color: #ffffff;
          border-radius: 6px;
          padding: 2px 7px;
          font-size: 10px;
          text-transform: uppercase;
        }
      }
    }
  }

  .programCtx {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 10px;

    &>div {
      display: flex;
      flex-direction: column;
      margin-bottom: 20px;
      width: 50%;
      label {
        font-size: 12px;
        text-transform: uppercase;
        font-weight: bold;
      }
      div {
        display: inline-block;
        font-size: 14px;
      }
    }
  }
  .mcc_selector {
    .react-select {
      margin-left: -15px;
      margin-right: -15px;
    }
  }

  .react-select {
    margin-left: -15px;
    margin-right: -15px;
  }

  .create_meal_pool {
    float: left;
    margin-top: 15px;
  }

  .pbm_pool {
    height: 200px;
    width: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 1px solid pink;
    border-radius: 6px;
    padding: 20px;
    text-align: center;
    cursor: pointer;

    &.created_pool {
      width: 50%;
    }

    &__create {
      width: calc(50% - 40px);
      border: 1px solid pink;
      padding: 20px;
      .programCtx {
        margin-top: 20px;
      }
    }

    i {
      font-size: 20px;
      height: 30px;
      width: 30px;
      border-radius: 50%;
      color: #ffffff;
      background-color: pink;
      display: flex;
      justify-content: center;
      align-items: center;
      font-style: normal;
      margin-bottom: 15px;
    }
  }
  .top-border {
    border: 1px solid pink;
    padding: 20px;
    width: 100%;
    border-radius: 6px;
  }
  .multi-top {
    width: calc(50% - 20px);
  }

  .pool-conditions {
    border: 1px solid pink;
    padding: 20px;
    width: 50%;
    float: right;
    border-radius: 6px;
    input {
      margin-bottom: 15px;
    }
  }
}