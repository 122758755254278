.qr-model {

}
.transfer-modal {
  .form-group {
    margin-bottom: 20px !important;

    &>label {
      margin-bottom: 0 !important;
      font-size: 12px;
      //text-transform: uppercase;
    }
  }
}

.modal-content {
  transform: translate(0, 0) !important;
}

.asset-logo-icon {
  display: inline-block;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  margin-right: 10px;
}

.tx-send-btn {
  width: 100%;
  margin-top: 20px !important;
}

.selected-vault {
  position: relative;
  img {
    width: 25px;
    height: 25px;
    border-radius: 0.4285rem;
    display: block;
    position: absolute;
    left: 7px;
    top: 7px;
  }
  input{
    padding-left: 50px !important;
  }
}
.balance-details {
  height: 8px;
  margin-top: -3px;
  margin-left: 8px;
  color: green;
  label {
    width: auto;
    font-size : 11px;
    margin-right: 5px;
    //color: #fff;
  }
  span {
    display: inline-block;
    font-size: 11px;
    //color: #fff;
  }
}
.source-selectors {
  display: none;
  position: relative;

  &.show {
    display: block;
  }
  .tab-menu {
    position: absolute;
    width: 100%;
    z-index: 9;
    margin-top: 5px;
    ul {
      display: flex;
      list-style-type: none;
      background: #ffffff;
      align-items: center;
      justify-content: space-between;
      padding-inline-start: 0;
      border-radius: 6px 6px 0 0;
      li {
        padding: 5px 15px;
        min-width: 80px;
        color: #000000;
        background: #fff;
        text-align: center;
        //cursor: pointer;
        border-bottom: 3px solid transparent;
        width: 100%;
        &.active {
          border: 1px solid #1d8cf8;
          background: #1d8cf8;
          color: #fff;
          border-radius: 5px 5px 0 0;
          height: 49px;
          margin-top: -10px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }

  .tab-body {
    position: absolute;
    width: 100%;
    z-index: 9;
    top: 44px;
    border: 1px solid #1d8cf8;
    background: #fff;
    img {
      width: 30px;
      height: 30px;
      margin-right: 20px;
      background: #efefef;
    }
    >ul {
      list-style-type: none;
      background: #ffffff;
      padding-inline-start: 0;
      display: none;
      max-height: 200px;
      overflow: scroll;
      border-radius: 0 0 5px 5px;
      &.active {
        display: block;
      }
      li {
        color: #000000;
        padding: 10px;
        border-bottom: 1px solid #1d8cf8;
        background: #fff;
        font-size: 13px;
        &:hover {
          background: #f8faff;
          cursor: pointer;
          color: #1d8cf8;
        }
      }
    }
  }
}