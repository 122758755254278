.navbar-absolute{
  .organization_logo {
    min-width: 40px;
    min-height: 40px;
    border: 1px solid #ccc;
    &:hover {
      cursor: pointer;
    }
  }
  .nav_org {
    position: fixed;
    display: flex;
    margin-left: -30px
  }
  .explore-products {
    margin-left: 160px;
    margin-top: 5px;
    z-index: 10;
    border-width: 0;
    border-radius: 4px;
    background: linear-gradient(135.37deg, #4361EE 2.49%, #7209B7 44.33%, #F72585 93.14%) !important;
    color: white !important;
    padding: 2px 16px;
    height: 34px;
    position: relative;
  }
}
.image-upload-modal {
  .fileinput .thumbnail {
    box-shadow: none !important;
  }
  .image_btn {
    background: transparent;
    padding: 7px 14px;
    font-size: 13px;
    font-weight: normal;
    &.select {

    }
    &.change {
      color: #ff6a00;
      border: 1px solid #ff6a00;
    }
    &.done {
      color: #21bb21;
      border: 1px solid #21bb21;
    }
    &.remove {
      color: #fd0000;
      border: 1px solid #fd0000;
    }
  }
}
//#1d8cf8
.pride-modal {
  .modal-content {
    border: 16px solid;
    border-image-slice: 1 !important;
    border-width: 8px;
    border-radius: 0;
    border-image-source: linear-gradient(to right, #743ad5, #d53a9d);
    width: 700px;
    background: #000000 !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.explore-products-content {
  text-align: center;
  display: flex;
  .products-section {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 20px;
    margin-top: -20px;
  }
  .vertical-separator-gradient {
    border-radius: 7px;
    height: 300px;
    width: 6px;
    margin: 8px;
    background: linear-gradient(to right bottom, #743ad5, #d53a9d);
    margin-top: 20px;
  }
  h4 {
    font-size: 18px;
    text-align: center;
    text-transform: uppercase;
    height: 60px;
    display: flex;
    align-items: center;
    padding: 0 20px;
  }
  p {
    margin-top: 30px;
    color: #ffffff !important;
    line-height: 24px;
    height: 100px;
  }
  button{
    border-color: white;
    border-radius: 4px;
    padding: 6px 14px;
    background: transparent !important;
    color: #ffffff;
    margin-top: 20px;
  }
}