.copy_hash {
  cursor: pointer;
  position: relative;

  &.long-hash {
    padding: 0 !important;
  }

  .copy-pointer {
    width: 100%;
    padding: 1px 10px;
    display: inline-block;
  }

  .copy-tooltip {
    position: absolute;
    width: 100px;
    top: -30px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    font-size: 11px;
    display: none;
    text-align: center;
    background: #000000;
    color: #ffffff;
    padding: 2px 3px 3px 3px;
    border-radius: 5px;

    &:before {
      content: "\A";
      border-style: solid;
      border-width: 7px 8px 7px 9px;
      border-color: transparent #000000 transparent transparent;
      position: absolute;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      transform: rotate(270deg);
      bottom: -57px;
      pointer-events: none;
    }
  }

  &:hover {
    .copy-tooltip {
      display: inline-block;
    }
  }
}