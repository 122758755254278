.asset-list {
  .photo {
    border-radius: 0 !important;

    &.kapow-logo {
      width: 25px;
    }
  }

  .crypto-column {
    display: flex;
  }
  .action-buttons button {
    padding: 7px 15px;
    margin-left: 10px;
    font-weight: 400;
  }
}
.btn-flip {

  :hover {

  }
}
.btn-flip:hover {

}
