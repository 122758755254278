.single-pool {
  margin-bottom: 1.5rem;
  border-color: #161522;
  height: 315px;
  box-shadow: 0 30px 42px -42px #a92258, 0 50px 250px -47px #3127a7;
  position: relative;
  //background: #000 !important;

  .pool-header {
    font-size: 15px;
    text-align: center;
    padding: 10px;
    //color: #fff;
    border-bottom: 1px solid grey;
    //background: #000;
    //border-radius: 15px 15px 0 0;

    .asset-logos {
      img {
        height: 40px;
      }
    }
  }
  .pool-body {
    padding-top: 5px;
    //background: #000;
    .straight {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    * {
      //color: #fff;
    }
    .pool-body-style {
      display: flex;
      flex-direction: column;

      >div {
        padding: 0 15px 20px 15px !important;
        display: flex;
        align-items: center;
        justify-content: space-between;
        span:nth-child(2) {
          font-size: 16px;
          display: inline-block;
        }
      }

      .lender-borrowers {
        display: flex;
        justify-content: space-between;
        height: 120px;
        padding: 10px 0 10px 15px !important;
        //background: #000 !important;
        position: relative;
        top: -4px;
        span:nth-child(1) {
          font-size: 14px;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-image: linear-gradient(225deg, #ff5ea7 36.04%, #6764ff 88.83%, #ff6530 220.3%);
        }
        div {
          font-size: 14px !important;
          text-align: center;
        }
      }
    }
  }
  .action-items {
    display: flex;
    position: absolute;
    bottom: 10px;
    left: 15px;
    width: 100%;
    button {
      width: calc(50% - 20px);
      padding: 11px 10px;
      //background: linear-gradient(90deg, #352db8, #ff5ea7) !important;
    }
  }
}

.maturity-date-picker {
  .slider-1 {
    width: 1000px;
  }
}

.hidden-pool {
  display: none !important;
}