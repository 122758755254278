.member-permissions {
  .card-tasks {
    //height: 690px;
  }
  .permission-checklist {
    margin-bottom: 30px;
    &.check-1 {
      margin-top: 30px;
    }
    table {

      td {
        border-top: 0;
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);
        height: 48px;
      }
    }
  }

  .perm-header {
    margin-top: 20px;
  }
  .permission-section {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    padding-bottom: 15px;
    margin-top: 10px;
  }
  .additional-permission {
    margin-top: 25px;
    &.choose-vault {
      margin-top: 10px;

      label {
        &.lock {
          color: #9b9b9b !important;
          cursor: text;
          .icon-lock-circle {
            color: #9b9b9b !important;
            font-size: 1.4em;
            margin-left: 5px;
            margin-top: -1px;
            cursor: pointer;
            height: 20px;
            width: 30px;
          }
        }
      }
    }
    h5 {
      margin-bottom: 10px;
    }
  }
}
