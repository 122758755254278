.transactions {
  .action-items-txs {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  .filter-tab-group {
    button {
      height: auto !important;
      &:after {
        top: 31px;
      }
      &.btn.btn-link:focus,
      &.btn.btn-link:hover {
        color: #e14eca !important;
      }
    }
  }

  td {
    font-size: 13px;
  }
  .long-hash {
    //background: #4f5fa1;
    //border-radius: 13px;
    //padding: 2px 10px;
    ////font-size: 13px;
    display: inline-block;
    color: #fff;
  }
  .date-time-class {
    font-size: 12px;
  }
  .accept-reject-btn-group {
    display: flex;
    .action-separator {
      margin: 3px 2px 0;
    }

    button {
      border: 0;
      width: auto;
      padding: 2px 0 0;
      margin: 0 4px;
      border-bottom: 1px solid #d73d32;
      outline: none;
      &.see-note-btn {
        color: #e14eca;
        border-bottom: 1px solid #e14eca;
      }
      &.approve-btn {
        color: green;
        border-bottom: 1px solid green;
      }
      &.reject-btn {
        color: #d73d32;
        border-bottom: 1px solid #d73d32;
      }
    }
  }
  .no-space {
    white-space: nowrap;
  }
  .hidden-space {

  }
  .status-599 {
    color: orange;
    text-transform: uppercase;
    font-size: 13px;
  }
  .status-600 {
    color: #67ae3f;
    text-transform: uppercase;
    font-size: 13px;
  }
  .status-612 {
    color: #d73d32;
    text-transform: uppercase;
    font-size: 13px;
  }
  .pagination {
    justify-content: center;
  }
}
.transaction-table {
  margin-top: 9px;
  table-layout: auto;
  width: 100%;
}
.filter-tab-group {
  margin-bottom: 20px;
  margin-left: 7px;
  display: flex;
  flex-direction: row;
  //&:after {
  //  content: "Filter";
  //  position: absolute;
  //  color: #fff;
  //  left: 7px;
  //  font-size: 10px;
  //}
  button {
    padding: 2px 10px;
    margin-right: 10px;
    margin-left: 0 !important;
    border-radius: 9px;
    width: auto;
    overflow: visible !important;
    height: 33px;
    border: 1px solid #000000;
    font-size: 12px;
    font-weight: normal;
    text-transform: uppercase;
  }
  .active-tab {
    //border-bottom: 2px solid #e14eca;
    color: #e14eca !important;
    position: relative;

    &:after {
      //content: "";
      position: absolute;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-bottom: 5px solid #e14eca;
      //top: -5px;
      //left: 40px;
      -webkit-transform: translate(-50%);
      left: 50%;
      transform: translate(-50%) rotate(180deg);
      top: 33px;
    }
  }
}

.title-wid-150 {
  display: inline-block;
  width: 100px;
  margin-bottom: 10px;
}
.value-wid {
  font-size: 12px;
}
.action-model {
  width: 200px;
}
.transaction-card {
  //color: #fff;
  .vertical-details {
    >span:first-child {
      //color: #fff;
      display: inline-block;
      //border: 1px solid red;
    }
    .date-time-class {
      font-size: 12px;
    }
  }
}
.approve-rejector-list {
  display: flex;
  margin-bottom: 20px;
  >div {
    width: 50%;
  }
}
.action-users {
  display: inline-block;
  border-radius: 50%;
  height: 32px;
  width: 32px;
  text-align: center;
}
.identicon-list {
  display: inline-block;
  position: relative;
  top: 10px;
  margin-left: 10px;

  .identicon {
    display: inline-block;
    border-radius: 50%;
    height: 32px !important;
    width: 32px !important;
    text-align: center;
    border: 1px solid #ccc;
  }
}


//background: #0052ff;
//background: linear-gradient(135.37deg, #4361EE 2.49%, #7209B7 44.33%, #F72585 93.14%) !important;
