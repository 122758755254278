.transactions {
  thead th {
    &:last-child {
      padding-left: 20px;
    }
  }
  td {
    padding: 0 7px;
    text-align: left;
    &.tx-action {
      padding-left: 20px !important;
      padding-top: 32px !important;
      sub {
        text-transform: capitalize;
        font-size: 12px !important;
        &:after {
          content: " | ";
        }
        &:last-child {

          &:after {
            content: " "  !important;
          }
        }
      }
    }
  }
}
