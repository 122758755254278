.apps {
  display: flex;

  .app-section:nth-child(even) {
    background: #fff;
  }
  .app-section:nth-child(odd) {
    background: #fff;
  }

  .app-section {
    display: flex;
    width: 25%;
    height: 295px;
    //border: 1px solid #ccc;
    align-items: center;
    justify-content: center;
    padding: 20px;
    position: relative;
    margin-right: 10px;

    .xchange-status {
      position: absolute;
      right: calc(50% - 18px);
      bottom: 10px;
    }

    img {
      //height: 200px;
      height: auto;
      width: 200px;
    }

    .text-label {
      margin-top: -25px;
      padding-bottom: 5px;
      font-size: 16px;
      font-weight: bold;
    }
  }

  .bottom-card {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  .single-app {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

}

.exchange-modal {
  transform: translate(0, 0) !important;
  .modal.show .modal-dialog {

  }
}

.blink_text {
  height: 11px;
  width: 11px;
  animation:1s blinker linear infinite;
  -webkit-animation:1s blinker linear infinite;
  -moz-animation:1s blinker linear infinite;
  color: green;
  background: gray;
  border-radius: 50%;
  display: inline-block;
  margin-right: 7px;
  position: relative;
  top: 4.5px;

  &.red {
    background: red;
  }
  &.green {
    background: green;
  }
}
.xchange-logo-box {
  width: 200px;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.x-connect-btn {
  //margin-top: 10px;
}

@-moz-keyframes blinker {
  0% { opacity: 1.0; }
  50% { opacity: 0.0; }
  100% { opacity: 1.0; }
}

@-webkit-keyframes blinker {
  0% { opacity: 1.0; }
  50% { opacity: 0.0; }
  100% { opacity: 1.0; }
}

@keyframes blinker {
  0% { opacity: 1.0; }
  50% { opacity: 0.0; }
  100% { opacity: 1.0; }
}
