.qr-model {
  .modal-header {
    justify-content: center;
  }
  .copy_address_link {
    cursor: pointer;
    sub {
      font-size: 12px;
      //color: #fff;
    }
    svg {
      //color: #fff;
      margin-left: 4px;
      margin-top: 2px;
    }
  }
}
.qr-styler {
  text-align: center;
  color: #000;
}

