.create-pool {
  background: linear-gradient(#222a42, #1d253b);
  background: #fff;
  width: 100%;

  .modal-header {
    border-bottom: none;
  }

  .card {
    background: transparent;
    box-shadow: none;
  }

  .rule-set {
    padding: 0 30px;
  }

  .input-group,
  .form-group,
  .rules-form-elements {
    display: flex;
    margin-bottom: 20px;
    padding: 10px 0;

    &>label {
      width: 140px;
      min-width: 140px;
      display: flex;
      align-items: center;
    }

    &.calc-width {
      >:nth-child(2) {
        min-width: calc(100% - 165px);
        flex: auto;
      }
    }
  }

  .react-select {
    margin-left: -15px;
  }

  .toggle-switch {
    padding-left: 7px;
  }

  .ml-n4 {
    padding-left: 13px;
  }

  .maturity-date-picker {
    .slider-1, .slider-2 {
      margin-top: 10px;
      .noUi-touch-area {
        &:before {
          content: attr(data-content);
          position: absolute;
          width: 80px;
          height: 20px;
          color: #344675;
          top: -20px;
          left: 2px;
        }
      }
    }
  }


  .full-slider {
    width: calc(100% - 125px);
  }

  //.maturity-date-picker {
  //  .rdt {
  //    input[type="text"] {
  //      width: 250px;
  //    }
  //  }
  //}
}
