.live-logs-opener {
    position: fixed;
    right: -47px;
    top: 300px;
    background: #181818;
    z-index: 99999;
    transform: rotate(-90deg);
    -webkit-transform: rotate(-90deg);
    width: 134px;
    text-align: center;
    cursor: pointer;
    color: #ffffff;
    font-size: 13px;
    padding: 12px 0 6px 0;
    height: 47px;
    border-radius: 9px 9px 0 0;
    box-shadow: 0 1px 15px 0 rgba(123, 123, 123, 0.01);
    &:hover {
        right: -46px;
        top: 299px;
        box-shadow: 0 1px 15px 0 rgba(123, 123, 123, 0.6);
    }
}
.audit-log-shadow {
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    .first-view {
        margin-top: 60px;
        .step-1__text {
            color: #ffffff;
            font-size: 15px;
        }
    }

    .active-tx-btn-group {
        position: fixed;
        top: 80px;
        z-index: 99;
        padding-left: 14px;

        button {
            height: 26px;
            padding: 2px 9px;
            border-radius: 7px;
            font-size: 11px;
            margin-right: 7px;
            font-weight: normal;
            text-transform: uppercase;
            //background: #fff;

            &.all {
                //background: green;
                color: #ffffff;
                border: 1px solid #ffffff;
            }
            &.confirmed {
                //background: green;
                color: #ffffff;
                border: 1px solid green;
            }
            &.pending {
                //background: yellow;
                color: #ffffff;
                border: 1px solid yellow;
            }
            &.rejected {
                //background: red;
                color: #ffffff;
                border: 1px solid #d73d32;
            }
            &.actions {
                //background: red;
                color: #ffffff;
                //border: 1px solid red;
            }
        }
    }
}

.tx-live-logs {
    //display: block;
    position: fixed;
    z-index: 999999;
    width: 400px;
    height: calc(100% - 10px);
    max-height: calc(100% - 10px);
    overflow: auto;
    right: 0;
    top: 5px;
    //border-radius: 6px;
    background: #181818;
    padding: 120px 15px 10px 15px;
    /*transform: translate3d(400px, 0, 0);*/
    //box-shadow: 0 -1px 9px 0 rgb(0 0 0), 0 4px 20px 0 rgb(0 0 0);
    display: none;

    &>.mask {
        content: "";
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: transparent;
        z-index: -1;
    }
    &.open {
        //transform: translate3d(0, 0, 0);
        display: block;
        box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);

    }
    &.open, h3 {
        border-radius: 9px 0 0 0;
    }
    h3 {
        text-align: center;
        position: fixed;
        right: 0;
        top: 5px;
        width: 398px;
        height: 110px;
        //border-radius: 6px 6px 0 0;
        padding: 0 20px 20px 20px;
        color: #ffffff !important;
        background: #181818;
        z-index: 99;
        text-transform: uppercase;
        display: flex;
        align-items: center;
        justify-content: center;
        span {
            position: absolute;
            right: 20px;
            font-size: 30px;
            cursor: pointer;
            color: #ffffff;
        }
    }

    .txData {
        //border: 1px solid purple;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 35px;
        background: #212121;
        border-radius: 10px 10px 0 0;

        &.t-599 {
            .tx-cards {
                border-bottom: 2px dashed  orange;
            }
        }
        &.t-600 {
            .tx-cards {
                border-bottom: 2px solid #67ae3f;
            }
        }
        &.t-612 {
            .tx-cards {
                border-bottom: 2px solid #d73d32;
            }
        }

        .tx-connector {
            height: 2px;
            width: 20px;
            border: 1px double transparent !important;
            border-radius: 0.375rem !important;
            background-image: linear-gradient(#161522, #161522), linear-gradient(90deg, #352db8, #ff5ea7) !important;
            background-origin: border-box !important;
            background-clip: padding-box, border-box !important;
        }
        ul {
            padding-inline-start: 0;
            list-style-type: none;
            li {
                display: flex;
                align-items: center;
                margin-bottom: 10px;
                justify-content: space-between;
                a {
                    color: #fff;
                    text-decoration:  underline;
                }
            }
            .tx-label {
                color: #fff;
                width: 70px;
                font-size: 12px;
                text-transform: uppercase;
            }
        }

        .roger-stone {
            height: 40px;
            width: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            border: 1px double transparent !important;
            border-radius: 0.375rem !important;
            background-image: linear-gradient(#fff, #fff), linear-gradient(90deg, #352db8, #ff5ea7) !important;
            background-origin: border-box !important;
            background-clip: padding-box, border-box !important;
        }

        .tx-cards {
            width: 100%;
            border-bottom: 3px solid #fdc448;
            padding: 90px 15px 25px 15px;
            position: relative;
        }

        .label {

        }
        .hash-link {
            //width: 50%;
            //overflow: hidden;
            //display: block;
            ////background: #27293d;
            //border-radius: 29px;
            //text-align: center;
            //padding: 2px;
            //margin-right: 10px;
        }

        .logs_to_from {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin-bottom: 7px;
            position: absolute;
            top: 0;
            left: 0;
            background: #000000;
            width: 100%;
            padding: 10px 5px 15px 5px;
            border-radius: 10px 10px 0 0;

            &>div {
                //background: #212121;
                padding: 2px 10px;
                //width: calc(50% - 5px);
                &>span {
                    color: #fff;
                    text-transform: uppercase;
                    font-size: 12px;
                }
            }
        }
    }

}

.txDetailsData {
    text-align: center;
    width: 100%;
    margin-top: 40px;
}
.txDetailsData h1 {
    font-size: 24px;
}
.txDetailsData>div {
    /* width: 90%; */
    display: inline-block;
    padding: 20px;
    text-align: left;
    height: 400px;
}

.txDetailsData>div ul li{
    padding: 14px 7px;
}

.txDetailsData>div ul li span:first-child {
    width: 200px;
    display: inline-block;
}

.status-type {
    font-size: 13px;
    color: #9b62ff;
}
.date-status-inside {
    display: flex;
    justify-content: space-between;
    .status-599 {
        color: orange;
        text-transform: uppercase;
        font-size: 13px;
    }
    .status-600 {
        color: #67ae3f;
        text-transform: uppercase;
        font-size: 13px;
    }
    .status-612 {
        color: #d73d32;
        text-transform: uppercase;
        font-size: 13px;
    }
}
.status-states {
    width: 70px;
    display: inline-block;
    color: #ffffff;
    font-size: 12px;
    text-transform: uppercase;
}