.linked_list {
  display: flex;

  .data-block {
    &>div {
      border-bottom: 1px solid #ccc;
    }
  }

  .linked_invoices {
    border: 2px dotted orange;
    width: calc(25% - 20px);
    margin-right: 20px;
    padding: 15px;
    &.current_invoice {
      border: 3px solid green;
    }
    .display_inline {
      display: inline-block;
    }
    .company_title {

    }
  }
  h1 {
    width: 100%;
    font-size: 14px;
    margin-bottom: 20px;
    text-align: center;
    color: #2770eb;
  }
  h2 {
    font-size: 14px;
    margin-top: 10px;
    margin-bottom: 7px;
    color: #2770eb;
  }
}