.long-hash {
  background: transparent;
  border-radius: 7px;
  font-size: 12px;
  display: inline-block;

  &.dark {
    color: #ffffff;
    border: 1px solid #000000;
    background: #000000;
  }
  &.light {
    color: #000000;
    border: 1px solid #ffffff;
    background: #ffffff;
  }
}

.name-clip {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  &>div {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.what-the-sex,
.new-sex {
  pointer-events: none !important;
}