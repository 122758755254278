.pool-group {
  display: flex;
  flex-wrap: wrap;

  .card {
    width: calc(25% - 20px) !important;
    max-width: calc(25% - 20px) !important;
    margin-right: 20px;
    border-radius: 6px;
    width: calc(33% - 0px) !important;
    max-width: calc(33% - 0px) !important;
    margin-right: 3px;
    border-radius: 0;
    height: 450px;
  }
}
.empty-pool-list {
  text-align: center;
  padding: 40px;
  color: #fff;
}