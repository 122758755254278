.rules-modal {
  //background: linear-gradient(#222a42, #1d253b);
  background: #fff;
  width: 100%;

  .modal-header {
    border-bottom: none;
  }

  .card {
    background: transparent;
    box-shadow: none;
  }

  .rule-set {
    padding: 0 30px;
  }

  .slider-1, .slider-2 {
    .noUi-touch-area {
      &:before {
        content: attr(data-content);
        position: absolute;
        width: 20px;
        height: 20px;
        //color: #fff;
        top: -20px;
        left: 2px;
      }
    }
  }

  .input-group,
  .form-group,
  .rules-form-elements {
    display: flex;
    margin-bottom: 20px;
    padding: 10px 0;

    &>label {
      width: 140px;
      min-width: 140px;
      display: flex;
      align-items: center;
    }

    &.calc-width {
      >:nth-child(2) {
        min-width: calc(100% - 125px);
        flex: auto;
      }
    }
  }

  .react-select {
    margin-left: -15px;
  }

  .slider-2,
  .slider-1 {
    margin-left: -15px;
    margin-top: 10px;
  }
}
