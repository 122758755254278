.login-view {
  .scan-login {
    cursor: text !important;
  }
  .login_progress {
    display: flex;
    justify-content: center;
    align-items: center;

    &>div:nth-child(2) {
      margin-left: 10px;
    }
  }
}