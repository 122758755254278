.top-loader {
  height: 2px;
  width: 100%;
  overflow: hidden;
  background-color: #ddd;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 111;
  will-change: opacity, transform;
  opacity: 0;
  transform: translateY(0);
  transition: opacity 0.25s ease-in-out;

  &.active {
    opacity: 1;
    transform: translateY(0);

    &:before {
      display: block;
      position: absolute;
      content: "";
      width: 200px;
      height: 4px;
      background-color: #fd5d93;
      will-change: transform;
      animation-delay: 0.25s;
      animation-duration: 2s;
      animation-iteration-count: infinite;
      animation-name: topLoader;
      transform: translateX(-200px);
    }
  }
}

@keyframes topLoader {
  0% {
    transform: translateX(-200px) scaleX(1);
    animation-timing-function: linear; }
  50% {
    transform: translateX(calc(50vw - (200px/2))) scaleX(3);
    animation-timing-function: ease-out; }
  100% {
    transform: translateX(100vw) scaleX(1); } }