.governance {
  .date-time-class {
    font-size: 12px;
  }
  .small-size {
    font-size: 12px;
  }
  .create-new-rule {

    button {
      margin-left: 15px;
      cursor: pointer;
      margin-bottom: 20px;
    }
  }
  .pagination {
    justify-content: center;
  }
}
