.transaction-model {
  .modal-content {
    width: 1130px !important;
    transform: translateX(-190px) !important;
    max-height: 400px;
    overflow-y: auto;
  }
  .modal-header {
    justify-content: center;
  }
}