.rules-modal.meals-modal {
  //background: linear-gradient(#222a42, #1d253b);
  background: #fff;
  width: 100%;

  .modal-header {
    border-bottom: none;
  }

  .card {
    background: transparent;
    box-shadow: none;
    .row {
      align-items: center;
    }
  }

  .rule-set {
    .input-parallel {
      display: flex;
      flex-direction: row;
    }
  }
  hr {
    margin-right: 0;
  }
  .stats {
    margin:10px 0 10px 15px !important;
  }
  .card-category {
    font-size: 13px !important;
    color: #4b4b4b !important;
    text-transform: uppercase;
    font-weight: bold;
  }
  .card-stats {
    border: 1px solid pink;
    &:hover {
      //background: rgba(156, 221, 255, 0.5);
      cursor: pointer;
    }

    &.active {
      background: #272a3e;
      * {
        color: #fff !important;
        border-color: #cccccc !important;
      }
    }
    .info-icon {

      i {

      }
    }
  }
  .meal-modal-flex-box {
    display: flex;
    flex-direction: row;
    margin: 0 -15px;
  }

  .slider-1, .slider-2 {
    .noUi-touch-area {
      &:before {
        content: attr(data-content);
        position: absolute;
        width: 20px;
        height: 20px;
        //color: #fff;
        top: -20px;
        left: 2px;
      }
    }
  }

  .input-group,
  .form-group,
  .rules-form-elements {
    display: flex;
    margin-bottom: 5px;
    padding: 10px 0;
    flex-direction: column;
    width: calc(100%);

    &>label {
      width: 140px;
      min-width: 140px;
      display: flex;
      align-items: center;
    }

    &>input {
      width: calc(100% - 20px);
    }

    &.calc-width {
      >:nth-child(2) {
        min-width: calc(100% - 125px);
        flex: auto;
      }
    }
  }

  .react-select {
    margin-left: -15px;
  }

  .slider-2,
  .slider-1 {
    margin-left: -15px;
    margin-top: 10px;
  }

}
