.invoices {
  //border: 1px solid blue;
  width: calc(100%);
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  transition: all 0.3s cubic-bezier(.25,.8,.25,1);
  margin-top: 20px;
  padding: 3px 20px;
  padding-bottom: 20px;
  &.proposal {
    box-shadow: none;
    //border: 1px solid #ccc;
    border-top: 1px solid #2381f8;
    background: #e7f0ff;
  }
  &__company {
    position: relative;
    font-size: 14px;
    display: flex;
    //border-bottom: 1px solid blue;
    height: 55px;
    align-items: center;
    .sb-avatar {
      padding-left: 10px;
    }
    .sb-avatar__text {
      border-radius: 50%;
    }
    &>div {
      float: left;
      font-size: 15px;
    }

  }
  .company_title {
    font-size: 15px;
    width: calc(100% - 30px);
    text-align: center;
  }
  .proposal_tag {
    font-size: 12px;
    position: absolute;
    right: 25px;
    color: orange;
    font-weight: bold;
    text-transform: uppercase;
  }
  .invoice_tag {
    font-size: 12px;
    position: absolute;
    right: 25px;
    color: cornflowerblue;
    font-weight: bold;
    text-transform: uppercase;
  }
  .invoices_body {
    display: flex;
  }
  .deal {
    &__size {

    }
  }
  .invoice-details {
    width: 100%;
    display: flex;
    align-items: center;
  }
  .accept_proposal {
    height: 45px;
    width: 200px;
  }

  .summary-block {
    display: flex;
    padding: 15px 15px 10px 20px;
    justify-content: center;
    text-align: center;
    margin-bottom: 10px;
    //width: calc(90% - 120px);
    width: calc(90% - 80px);
    .long-hash {
      font-size: 14px;
    }
  }
  .show_proposals {
    height: 40px;
    width: 250px;
    margin-right: 20px;
    margin-top: 25px;
  }

  .summary-block>div {
    padding: 0 15px 0 15px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: baseline;
    justify-content: center;
    height: 65px;
    width: 33.33333%;
  }

  .summary-block>div div {
    font-size: 16px;
  }

  .summary-block h2 {
    color: #8a8dba;
    font-size: 12px;
    margin-bottom: 10px;
    height: 30px;
  }

  .summary-block>div:before {
    background-color: #87e1a9;
    border-radius: 2px;
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 4px;
  }
  .accepted_tag {
    color: green;

    padding: 5px 10px;
    font-weight: bold;
    font-size: 13px;
    border-radius: 5px;
    float: right
  }
  .state-bar {
    width: 100%;
    height: 20px;

    text-align: center;
    .green {
      background: green;
      color: white;
      text-align: center;
    }
    .red {
      background: orange;
      color: white;
      text-align: center;
    }
    &>div {
      width: 20%;
      float: left;
      padding: 0 10px;
      text-align: center;
      border-right: 1px solid #fff;
      font-size: 14px;
    }
  }
}