.accounts-view {
}

.generic-modal-dark {
  background-color: #212841;
}

.breadcrumb {
  padding: 0.3rem 1rem;
  margin-bottom: 1rem;
  //background-color: #1d253b;
  background-color: transparent;
  font-size: 15px;
}

.address-detail-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.new-vault {
  float: right;
  border: 1px solid white;
  border-radius: 4px;
  padding: 6px 11px 5px 11px;
  cursor: pointer;
}

.vault-list {
  .pagination {
    justify-content: center;
  }
}

.connect-exchange-modal {
  input[type="text"] {
    width: 100%;
  }
}


//input{
//  color: #fff !important;
//}

.asset-logo-pop {
  text-align: center;
  //color: red;
  img {
    height: 40px;
  }
}
.lending_details {
  margin-bottom: 7px;
  label {
    &:first-child {
      width: 170px;
      //border: 1px solid red;
      display: inline-block;
    }
    &:nth-child(2) {
      font-weight: bold;
    }
  }
}

.asset-icons-list {
  div {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    border: 1px solid #fff;
    display: inline-block;
    position: relative;
    //margin-right: 3px;
    margin-left: 5px;
    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
    }
  }
}

.vault-logo {
  display: flex;
  justify-content: center;
  width: 100%;

  &.vault-logo-create {
    div {
      width: 150px !important;
      height: 150px !important;
      background: none !important;
    }
  }
  div {
    width: 45px;
    height: 45px;
    border-radius: 7px;
    background: #e8e8e8;
    img {
      width: 100%;
      height: 100%;
    }
  }

}
.avatar-list {
  text-align: center;
  >div {
    display: inline-block;
    padding: 0 1px;
    .sb-avatar__text {
      border-radius: 5px;
      cursor: pointer;
      font-family: "Poppins", sans-serif;
    }
    .sb-avatar--text {
      position: relative;
      & + i {
        
      }
    }
    .icon-check-2,
    .icon-simple-remove {
      font-weight: bold;
      padding: 3px;
      border: 1px solid black;
      border-radius: 50%;
      font-size: 8px;
      position: relative;
      left: -35px;
      bottom: -14px;
    }
    .icon-check-2 {
      color: #FFFFFF !important;
      border-color: #FFFFFF;
      background: green;
    }
    .icon-simple-remove {
      color: #FFFFFF !important;
      border-color: #FFFFFF;
      background: red;
    }
  }
  button {
    &.add_member {
      width: 34px;
      height: 34px;
      border-radius: 50%;
      padding: 0;
      margin-left: 5px;
      font-size: 22px;
      font-weight: normal;
      //background: #1d8cf8;
      color: #1d8cf8;
      &:hover {
        //background: #1d8cf8 !important;
        //color: #fff;
      }
    }
  }
}
.first-view {
  padding: 20px;
  .step-1 {
    .team-image {
      text-align: center;
      img {
        width: 250px;
      }
    }
    &__text {
      font-size: 18px;
      text-align: center;
      padding-top: 20px;
      &__sub {
        font-size: 13px;
        color: #706f6f;
      }
    }
    &__action {
      margin-top: 15px;
      text-align: center;
    }

  }
}

.invoice_filter {

  span {
    display: inline-block;

    margin-left: 5px;
    &:hover {
      color: #2185f7;
      cursor: pointer;
    }
  }
}

.no-show {
  display: none;
}
.zero-h {
  height: 0 !important;
}

.click_pointer {
  height: 13px;
  width: 13px;
  background: orange;
  border-radius: 50%;
  display: inline-block;
  margin-left: 10px;
  position: relative;
  top: -4px;
}

.no_select {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

a[href="#pablo"]:before {
  opacity: 0;
}

.pending_rows {
  color: orange;
  font-size: 12px;
}
.pending_parent {
  display: flex;
  justify-content: center;
  align-items: center;
}