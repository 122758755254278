.admin-console {
  .people-top-bar {
    display: flex;
    justify-content: space-between;
    >div {
      width: 40%;
      &.email-invite-holder {
        width: calc(60% - 120px);
        position: relative;
      }
      input {
        width: calc(100% - 30px);
        height: 33px;
        padding-left: 10px;
        color: #000 !important;
      }
      .icon-zoom-split {
        padding-left: 10px;
        position: relative;
        left: -40px;
      }
      Button {
        border-radius: 0;
        height: 33px;
        width: 120px;
        line-height: 10px;
        display: inline-block;
        position: absolute;
        right: -90px;
        top: -4px;
      }
    }

    .new-vault {
      //color: #fff;
      float: right;
      border: 1px solid #e14eca;
      width: 165px;
    }
  }

  .pagination{
    justify-content: center;
  }

  .member-list {
    margin-top: 20px;
    table-layout: fixed;
    width: 100%;
  }
  .sb-avatar__text {
    border-radius: 50%;
  }

  .filter-tab-group {
    margin: 10px 10px;
    border-bottom: 1px solid #cccccc;

    button {
      padding-bottom: 0;
      border: 0;
      border-radius: 0;
      margin-bottom: -1px;
      border-bottom: 2px solid transparent;
      font-size: 13px;

      &.btn.btn-link:focus,
      &.btn.btn-link:hover,{
        color: #e14eca !important;
      }
      &.active-tab {
        border-bottom: 2px solid #e14eca;
      }
    }
  }
}